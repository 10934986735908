import { render, staticRenderFns } from "./checkcost.vue?vue&type=template&id=e5f1b216&scoped=true&"
import script from "./checkcost.vue?vue&type=script&lang=js&"
export * from "./checkcost.vue?vue&type=script&lang=js&"
import style0 from "./checkcost.vue?vue&type=style&index=0&id=e5f1b216&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5f1b216",
  null
  
)

export default component.exports