<template>
  <div class="cost">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="专利列表"
      beforeTitle="费用"
    />
    <div class="content">
      <h1>专利费用</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="申请费（元）">
          <el-input v-model="form.app_fee"></el-input>
        </el-form-item>
        <el-form-item label="公开费（元）">
          <el-input v-model="form.publish_fee"></el-input>
        </el-form-item>
        <el-form-item label="实审费（元）">
          <el-input v-model="form.examination_fee"></el-input>
        </el-form-item>
        <el-form-item label="授权费（元）">
          <el-input v-model="form.authorize_fee"></el-input>
        </el-form-item>
        <el-form-item label="其他费（元）">
          <el-input v-model="form.other_fee"></el-input>
        </el-form-item>

        <div class="clear"></div>
        <div class="ImportTop">
          <el-upload
            class="upload-demo"
            v-model="form.file_name"
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
          >
            <el-button type="primary" class="ChooseFile">请选择文件上传</el-button>
          </el-upload>
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
          <el-link
            v-model="form.url"
            v-if="this.form.url"
            :href="userSite + this.form.url"
            target="_blank"
          >查看附件</el-link>
        </div>
        <h1>专利年费</h1>
        <el-table :data="form.annual_fee" style="width: 100%" :key="Math.random()">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button type="primary" @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="primary"
                v-if="form.annual_fee.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="缴费次数(如第1年，只写数字“1”即可)">
            <template slot-scope="scope">
              <el-input placeholder="请输入第几年" v-model="scope.row.year"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="缴费时间/费用(元)">
            <template slot-scope="scope">
              <el-input placeholder="请输入缴费时间/费用，如: 2023/11/28付900" v-model="scope.row.fee"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="附件">
            <template slot-scope="scope">
              <el-upload
                class="upload-demo"
                ref="imgupload"
                action="string"
                :name="'' + scope.$index"
                :http-request="Uploadfile"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList"
              >
                <el-button type="primary" class="fileUpload">上传</el-button>
              </el-upload>
              <el-link v-if="scope.row.url" :href="userSite + scope.row.url" target="_blank">
                <el-button class="edit" size="mini">查看</el-button>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      userSite: site.userSite,
      labelPosition: "top",
      form: {
        app_fee: "",
        publish_fee: "",
        examination_fee: "",
        authorize_fee: "",
        other_fee: "",
        annual_fee: [],
        patent_id: "",
        file_name: "",
        url: "",
        file_id: "",
      },
      url: "",
      fileList: [],
      formRule: {},
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    this.axios
      .get("/api/patent/view_fee", {
        params: {
          patent_id: this.meber_id,
        },
      })
      .then((res) => {
        if (res.data)
          (this.form = []),
            // this.form.file_name="",
            // this.form.url="",
            // this.form.file_id="",

            (this.form = res.data);
        // this.form.file_name=res.data.file_name
        // this.form.url=res.data.url
        // this.form.file_id=res.data.id
      });
  },
  methods: {
    httpRequest (param) {
      console.log(param);
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("type", "2");
      this.upload("/api/patent/upload_file", form).then((res) => {
        console.log(res);
        this.form.file_name = res.data.name;
        this.form.url = res.data.url;
        this.form.file_id = res.data.id;
      });
    },
    Uploadfile (param) {
      let file = param.file;
      let cindex = param.filename;
      const form = new FormData();
      form.append("file", file);
      form.append("type", "3");
      console.log(param);
      this.upload("/api/patent/upload_file", form).then((res) => {
        console.log(res);
        this.form.annual_fee[cindex].url = res.data.url;
        this.form.annual_fee[cindex].file_name = res.data.name;
        this.form.annual_fee[cindex].file_id = res.data.id;
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.form.patent_id = this.meber_id;
          console.log(this.form);
          this.axios
            .post("/api/patent/store_fee", this.form)
            .then((res) => {
              console.log(res);
              this.$router.push("/patent/index");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("");
          this.btnLoading = false
        }
      });
    },
    addLine () {
      //添加行数
      this.form.annual_fee.push({
        year: "",
        fee: "",
        url: "",
      });
    },
    handleDelete (index) {
      //删除行数
      this.form.annual_fee.splice(index, 1);
    },
  },
};
</script>

<style scoped="scoped">
.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}
.fileUpload {
  background: #f2f3f9 !important;
  border-radius: 2px;
  color: #0f38ff !important;
  border: none;
  font-weight: bold;
  padding: 7px 15px;
  font-size: 12px;
  margin-right: 20px;
}
.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
